var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('div',{staticClass:"w-full bg-gray-100 dark_bg-dark-100 flex items-center",class:{ 'rounded-full': _vm.rounded, 'rounded': !_vm.rounded }},_vm._l((_vm.tabTitles),function(title){return _c('div',{key:title,staticClass:" flex items-center justify-center flex-1 cursor-pointer duration-150 select-none",class:{
        'h-8 text-xs font-bold': _vm.size === 'small',
        'bg-brand text-white': title === _vm.selectedTab && _vm.type === 'brand',
        'bg-navy text-white': title === _vm.selectedTab && _vm.type === 'navy',
        'text-gray-600 dark_text-gray-500': title !== _vm.selectedTab,
        'hover_bg-gray-200 hover_text-navy dark_hover_bg-dark-200 dark_hover_text-gray-500': title !== _vm.selectedTab && _vm.disabledTabs.indexOf(title) === -1,
        'rounded-full': _vm.rounded,
        'rounded': !_vm.rounded,
        'cursor-not-allowed': _vm.disabledTabs.indexOf(title) > -1
      },on:{"click":function($event){return _vm.onSelectTab(title)}}},[_vm._v(" "+_vm._s(title)+" ")])}),0),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }