<template>
  <div class="bg-white dark_bg-dark shadow-md rounded text-navy dark_text-white">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'MjCard'
};
</script>
