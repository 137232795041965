var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.buttonTag,_vm._b({tag:"component",staticClass:"select-none relative inline-flex items-center justify-center rounded font-bold duration-150 disabled_cursor-not-allowed disabled_opacity-75 leading-none",class:{
    'bg-navy text-white hover_bg-navy-dark focus_bg-navy': _vm.type === 'primary',
    'bg-gray-200 text-ternary hover_bg-gray-400 border border-gray-400 dark_bg-dark-200 dark_text-white dark_border-transparent dark_hover_bg-dark-300': _vm.type === 'secondary',
    'bg-brand text-white hover_bg-brand-dark': _vm.type === 'brand',
    'bg-success text-white hover_bg-success-dark': _vm.type === 'success',
    'bg-danger text-white hover_bg-danger-dark': _vm.type === 'danger',
    'bg-transparent text-ternary hover_text-ternary-dark': _vm.type === 'transparent',
    'h-[40px] text-xs px-4': _vm.size === 'normal',
    'h-[30px] text-xxs px-3': _vm.size === 'small'
  },style:(_vm.styles),on:{"click":function($event){return _vm.$emit('click')}}},'component',_vm.$attrs,false),[_c('div',{staticClass:"flex-none whitespace-nowrap"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }