
<template>
  <svg width="100%" height="100%" viewBox="0 0 20 20"><path d="M12.3 3.7l4 4L4 20H0v-4L12.3 3.7zm1.4-1.4L16 0l4 4l-2.3 2.3l-4-4z" fill="currentColor"></path></svg>
</template>

<script>
export default {
  name: 'MjEditIcon'
};
</script>
