<template>
  <div class="select-none inline-flex items-center justify-center h-[22px] w-[40px] bg-navy text-white rounded-full font-extrabold text-xxs">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'MjHeadTag'
};
</script>
