var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"enter-active-class":_vm.transition.enter,"leave-active-class":_vm.transition.leave}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActive),expression:"isActive"}],staticClass:"toast",class:[
      {
        'bg-white dark_bg-dark-200': _vm.type === 'normal',
        'bg-success dark_bg-success': _vm.type === 'success',
        'bg-danger dark_bg-danger': _vm.type === 'danger',
        'bg-brand dark_bg-brand': _vm.type === 'brand'
      },
      _vm.position
    ],attrs:{"aria-hidden":!_vm.isActive,"role":"alert"},on:{"mouseenter":_vm.pause,"mouseleave":_vm.removePause}},[(_vm.$slots.default)?[_vm._t("default")]:[_c('div',{class:{
          'text-navy dark_text-white': _vm.type === 'normal',
          'text-white': _vm.type !== 'normal'
        },domProps:{"innerHTML":_vm._s(_vm.message)}})]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }