var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,_vm._b({tag:"component",class:{
    'text-navy dark_text-white': _vm.type === 'normal',
    'text-ternary dark_text-gray-500': _vm.type === 'ternary',
    'text-gray-500 dark_text-dark-200': _vm.type === 'gray-500',
    'select-none': !_vm.select,
    'hover_text-gray-600 dark_hover_text-gray-200': _vm.type === 'gray-500' && _vm.hoverable,
    'text-success': _vm.type === 'success',
    'animation duration-150': _vm.hoverable
  }},'component',_vm.$attrs,false),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }