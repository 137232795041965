var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full bg-gray-100 dark_bg-dark-200 rounded-full relative",style:({ height: _vm.height })},[_c('div',{staticClass:"rounded-full w-full bg-stripe-light dark_bg-stripe-dark h-full",staticStyle:{"background-size":"1em 1em"}}),_c('div',{staticClass:"absolute top-0 left-0 h-full rounded-full",class:{
      'bg-brand': _vm.type === 'brand',
      'bg-navy': _vm.type === 'navy',
      'bg-success': _vm.type === 'success',
      'bg-danger': _vm.type === 'danger',
    },style:({
      width: ((_vm.value * 100) + "%")
    })})])}
var staticRenderFns = []

export { render, staticRenderFns }